@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

@font-face {
    font-family: 'Jalnan2';
    src: url('./styled/fonts/Jalnan2.woff2') format('woff2'),
    url('./styled/fonts/Jalnan2.otf') format('opentype');
}

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 100;*/
/*    src: url('./styled/fonts/100_AppleSDGothicNeo-Thin.woff2') format('woff2'),*/
/*    url('./styled/fonts/100_AppleSDGothicNeo-Thin.otf') format('opentype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 200;*/
/*    src: url('./styled/fonts/200_AppleSDGothicNeo-UltraLight.woff2') format('woff2'),*/
/*    url('./styled/fonts/200_AppleSDGothicNeo-UltraLight.otf') format('opentype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 300;*/
/*    src: url('./styled/fonts/300_AppleSDGothicNeo-Light.woff2') format('woff2'),*/
/*    url('./styled/fonts/300_AppleSDGothicNeo-Light.otf') format('opentype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: url('./styled/fonts/400_AppleSDGothicNeo-Medium.woff2') format('woff2'),*/
/*    url('./styled/fonts/400_AppleSDGothicNeo-Medium.otf') format('opentype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*    src: url('./styled/fonts/500_AppleSDGothicNeo-Regular.woff2') format('woff2'),*/
/*    url('./styled/fonts/500_AppleSDGothicNeo-Regular.otf') format('opentype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    src: url('./styled/fonts/600_AppleSDGothicNeo-SemiBold.woff2') format('woff2'),*/
/*    url('./styled/fonts/600_AppleSDGothicNeo-SemiBold.otf') format('opentype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    src: url('./styled/fonts/700_AppleSDGothicNeo-Bold.woff2') format('woff2'),*/
/*    url('./styled/fonts/700_AppleSDGothicNeo-Bold.otf') format('opentype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 800;*/
/*    src: url('./styled/fonts/800_AppleSDGothicNeo-ExtraBold.woff2') format('woff2'),*/
/*    url('./styled/fonts/800_AppleSDGothicNeo-ExtraBold.otf') format('opentype');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Apple SD Gothic Neo';*/
/*    font-style: normal;*/
/*    font-weight: 900;*/
/*    src: url('./styled/fonts/900_AppleSDGothicNeo-Heavy.woff2') format('woff2'),*/
/*    url('./styled/fonts/900_AppleSDGothicNeo-Heavy.otf') format('opentype');*/
/*}*/
